import React from 'react';

import './styles.scss';

const ErrorMessage: React.FC = ({ children }) => {
  return (
    <div className="error">
      <div className="message">
        <h1>{children}</h1>
      </div>
    </div>
  );
};

export default ErrorMessage;
