import React from 'react';
import { SpinnerRoundFilled } from 'spinners-react';

import './styles.scss';

const Loader: React.FC = () => {
  return (
    <div className="loader">
      <SpinnerRoundFilled color="#128c7e" />
    </div>
  );
};

export default Loader;
