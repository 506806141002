import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';

import { IoMdSend } from 'react-icons/io';
import { parseDomain } from 'parse-domain';

import Message from './Message';

import './App.scss';
import ErrorMessage from './ErrorMessage';
import Loader from './Loader';

const App: React.FC = () => {
  const [linkInfo, setLinkInfo] = useState<{
    id: string;
    type: string;
    subdomain: string;
    path: string;
    dest: string;
    url: string;
    title?: string;
    body?: string;
  }>();

  const [curr, setCurr] = useState<string>('name');
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');

  const [messages, setMessages] = useState<any>([]);
  const [text, setText] = useState('');

  const domain = parseDomain(window.location.hostname);
  const hasSubdomain = domain.type === 'LISTED' && domain.subDomains.length > 0;

  const subdomain = hasSubdomain ? domain.subDomains[0] : '';
  const path = window.location.pathname.replace(/[^a-zA-Z0-9]/g, '');

  //-----------------------Queries--------------------------------------------

  const { data, loading, error } = useQuery(link_info, {
    fetchPolicy: 'cache-and-network',
    variables: {
      subdomain,
      path,
    },
  });

  //-----------------------Mutation---------------------------------------------

  const [SendInfo] = useMutation(send_info);

  //----------------------------------------------------------------------------
  function addMessage(message: string, bot: boolean, delay: number) {
    setTimeout(
      () => setMessages((prev: any) => [...prev, { message, bot }]),
      delay * 1000,
    );
  }

  useEffect(() => {
    if (!data || !data.linkSearch) return;
    setLinkInfo(data.linkSearch);

    setTimeout(() => {
      addMessage('Olá, qual é o seu nome?', true, 0);
    }, 500);
  }, [data]);

  useEffect(() => {
    if (!linkInfo) return;
    if (linkInfo.type === 'default') window.location.replace(linkInfo.dest);
  }, [linkInfo]);

  useEffect(() => {
    if (!phone) return;

    async function sendInfo(name: string, email: string, phone: string) {
      if (!linkInfo) return;

      try {
        await SendInfo({
          variables: {
            id: linkInfo.id,
            name,
            email,
            phone,
          },
        });
      } catch (err) {
        console.log(err);
      } finally {
        window.location.replace(linkInfo.dest);
      }
    }

    sendInfo(name, email, phone);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone]);

  function handleInput() {
    if (curr === 'name') {
      setName(text);
      setCurr('email');

      addMessage(text, false, 0);
      addMessage(`E qual é o seu email?`, true, 0.5);
    } else if (curr === 'email') {
      setEmail(text);
      setCurr('phone');

      addMessage(text, false, 0);
      addMessage('Só mais uma coisa, qual é o seu telefone?', true, 0.5);
    } else if (curr === 'phone') {
      setPhone(text);
      setCurr('');
      addMessage(text, false, 0);
    }

    setText('');
  }

  function onPressEnter(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'space') {
      return;
    }
    if (event.key === 'Enter') {
      handleInput();
    }
  }

  function onChangeText({ target }: React.ChangeEvent<HTMLInputElement>) {
    setText(target.value);
  }

  if (loading) return <Loader />;

  if (error || !linkInfo)
    return (
      <ErrorMessage>
        O link que você está tentando acessar não existe.
      </ErrorMessage>
    );

  return (
    <div className="App">
      <div className="box">
        <div className="header">
          <h1>{linkInfo.title}</h1>
          <p>{linkInfo.body}</p>
        </div>
        <div className="chatBox">
          {messages.map(({ message, bot }: any, idx: number) => {
            return <Message key={idx} message={message} bot={bot} />;
          })}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <input
            value={text}
            onChange={onChangeText}
            onKeyPress={onPressEnter}
            placeholder="Escreva uma mensagem"
          />
          {text && (
            <button onClick={() => handleInput()}>
              <IoMdSend
                color={'#ffffff'}
                size={22}
                style={{ paddingLeft: 2, paddingTop: 2 }}
              />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default App;

const link_info = gql`
  query LinkSearch($subdomain: String!, $path: String!) {
    linkSearch(subdomain: $subdomain, path: $path) {
      id
      type
      subdomain
      path
      dest
      url
      ... on WhatsAppLink {
        title
        body
      }
    }
  }
`;

const send_info = gql`
  mutation SendLinkInfo(
    $id: ID!
    $name: String!
    $email: String!
    $phone: String!
  ) {
    sendLinkInfo(id: $id, name: $name, email: $email, phone: $phone)
  }
`;
