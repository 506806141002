import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import './styles.scss';

interface Props {
  message: string;
  bot: boolean;
}

const animations = {
  initialBot: {
    x: '-100px',
    opacity: 0,
    transform: 'scale(0)',
  },
  initial: {
    x: '100px',
    opacity: 0,
    transform: 'scale(0)',
  },
  show: {
    x: 0,
    opacity: 1,
    transform: 'scale(1)',
    transition: { duration: 0.5 },
  },
};

const Message: React.FC<Props> = ({ message, bot }) => {
  const [animation, setAnimation] = useState('initial');

  useEffect(() => {
    setAnimation('show');
  }, []);

  return (
    <motion.div
      variants={animations}
      initial={bot ? 'initialBot' : 'initial'}
      animate={animation}
      className="container"
      style={{
        justifyContent: bot ? 'flex-start' : 'flex-end',
        verticalAlign: 'bottom',
      }}>
      {bot && <div className={'tailBot'} />}
      <div className={bot ? 'messageBot' : 'message'}>
        <p
          style={{
            alignItems: 'center',
            margin: '6px',
          }}>
          {message}
        </p>
      </div>
      {!bot && <div className={'tail'} />}
    </motion.div>
  );
};

export default Message;
