import React, { useMemo } from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

import App from './App';

const ApolloRoot: React.FC = () => {
  const client = useMemo(() => {
    return new ApolloClient({
      cache: new InMemoryCache(),
      uri:
        process.env.NODE_ENV === 'production'
          ? 'https://graph.idaction.com.br'
          : 'http://localhost:5000',
    });
  }, []);

  //-----------------------------------------------------------------------------
  return (
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  );
};

export default ApolloRoot;
